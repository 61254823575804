import { useRef, useState } from "react";
import { CSVDownload } from "react-csv";
import { MdClose } from "react-icons/md";
import useApi from "../../../../hooks/useApi";
import { Modal } from "../../../../components/Modal";
import { Button, CloseButton, Container, Title } from "./styles";
import { GatewayData } from "./types";
import queryString from "../../../../utils/queryString";
import { useLocation } from "react-router-dom";

export const ExportGatewaysCSV = () => {
  const csvRef = useRef<any>(null);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<String>("");
  const [completed, setCompleted] = useState(false);
  const [data, setData] = useState<any>([]);
  const location = useLocation();

  const { request } = useApi({
    path: `/summary/allGateways`,
  });

  const date = new Date();

  async function loadData() {
    setMessage("Buscando dados...");
    setCompleted(false);

    const queryStringParams = queryString.parse(location.search);

    try {
      const response = await request({
        method: "get",
        queryStringParameters: queryStringParams,
      });

      if (!Array.isArray(response) || response.length === 0) {
        console.error("Response is empty or not an array");
        setMessage("Nenhum dado encontrado");
        setCompleted(true);
        return;
      }

      const formattedData = response.map((item: GatewayData) => {
        return {
          UUID: item.gatewayId,
          "Ultima Atualização": new Date(item.date).toLocaleString(),
          "Status de Ativação": item.isActivated ? "Ativado" : "Desativado",
          "Status de Conexão": item.isConnected ? "Conectado" : "Desconectado",
          Conectividade: item.connectivityPercent,
          "Falha SDCard": item.sdCardStatusCount,
          "Última Conexão": new Date(item.healthCheckDate).toLocaleString(),
          ID: item.gatewayId,
          SdCard: item.sdCardType,
          "SdCard MID": item.sdCardMID,
          Firmware: item.firmware,
          Empresa: item.companyName,
          Unidade: item.facilityName,
          Setor: item.sector,
          Nome: item.name,
          "Quantidade de Slots": item.radioWindowNumBoardSlots,
          "Duração Janela de Configuração": item.configWindow,
          "Intervalo entre Transmissões Globais": item.radioWindowInterval,
          "Aquisição entre Globais": item.dataAcquisitionInterval,
          "Intervalo entre Dinâmicas": item.dynamicTxInterval,
          "Frequência de Operação MHz": item.operationFrequency,
          "Tipo de Conexão Cloud": item.cloudConnection,
          "Tempo Ligado":
            item.uptimeDays !== 0
              ? item.uptimeDays + " dia(s)"
              : item.uptimeHours !== 0
              ? item.uptimeHours + " hora(s)"
              : "Há poucos minutos",
          "Potência Sinal Cloud": item.connectionRssi,
          "Endpoints Sincronizados": item.boardsSchedules,
          "Alimentação Elétrica": item.powerSupply,
          "Tensão da Bateria": item.batteryVoltage,
          "Recall Programado": item.recallForecast ? "Sim" : "Não",
          "Recall Realizado": item.recallPerformed ? "Sim" : "Não",
          partNumber: item.partnumber,
          manufacturer: item.manufacturer,
          Lote: item.batch,
          simCCID: item.simCCID,
        };
      });

      setData(formattedData);
      setCompleted(true);
      setMessage("Dados carregados com sucesso");
    } catch (error) {
      console.error("Error fetching data:", error);
      setMessage("Erro ao buscar dados");
      setCompleted(true);
    }
  }

  function handleLoadData() {
    loadData();
    setShow(true);
  }

  return (
    <>
      <Button onClick={handleLoadData}>Exportar CSV</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        confirmLabel="Baixar"
        size="sm"
        disabledConfirm={false}
        changeFotter
      >
        <Container>
          <CloseButton onClick={() => setShow(false)}>
            <MdClose size={18} />
          </CloseButton>
          <Title>
            <strong>{message}</strong>
          </Title>
          {completed && (
            <CSVDownload
              separator=";"
              ref={csvRef}
              data={data.length > 0 ? data : []}
              filename={"gateways_" + date.toLocaleDateString() + ".csv"}
              asyncOnClick={true}
            ></CSVDownload>
          )}
        </Container>
      </Modal>
    </>
  );
};
