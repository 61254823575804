import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 0.3rem;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  background: ${(props) => props.theme.colors.cardBackground};
  color: ${(props) => props.theme.colors.iconsPrimary};
  cursor: pointer;
  align-items: center;
  display: flex;
  ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 6px;
  padding: 0.2rem 0.5rem;

  :hover {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    transition: 0.1s ease all;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.2rem;
  cursor: pointer;

  svg {
    color: ${(props) => props.theme.colors.iconsPrimary};

    &:hover {
      color: ${(props) => props.theme.colors.statusRisk};
    }
  }
`;
