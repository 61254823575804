import { Button, PageTemplate } from "../../components";
import {
  AddDeviceButton,
  AddSensorContainer,
  Container,
  Footer,
  Header,
  Lotdescription,
  RefreshButton,
  StepProgress,
  WrapperStages,
} from "./styles";
import { DebounceInput } from "react-debounce-input";
import { useEffect, useMemo, useState } from "react";
import PreBurninInspection from "./PreBurninInspection";
import BurninInspection from "./BurninInspection";
import PostBurninInspection from "./PostBurninInspection";
import ExpeditionInspection from "./ExpeditionInspection";
import { IDevices, IInpectionDetails, InspectionStepsEnum } from "./types";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { ModalConfirm } from "../../components/ModalConfirm";
import { Placeholder, Spinner } from "react-bootstrap";
import { DeviceContainer } from "./modals/DeviceContainer";
import { MdAdd } from "react-icons/md";
import { format } from "date-fns";

export default function QualityInspections() {
  const navigate = useNavigate();
  const inspectionId = useParams()?.id;
  const [devices, setDevices] = useState<IDevices[]>();
  const [value, setValue] = useState("");
  const [stage, setStage] = useState<InspectionStepsEnum>(
    InspectionStepsEnum.INITIAL_VISUAL
  );
  const [inspectionDetails, setInspectionDetails] =
    useState<IInpectionDetails>();

  const { request: requestDevice, processing: loadDevice } = useApi({
    path: "/infra/quality-inspections/device",
  });
  const { request: requestInspection, processing: loadInspection } = useApi({
    path: "/infra/quality-inspections",
  });
  const { request: requestDevices, processing: loadDevices } = useApi({
    path: `/infra/quality-inspections/${inspectionId}/devices`,
  });

  function getDevices({
    query,
  }: { query?: { isApproved: boolean } | string } = {}) {
    requestDevices({ method: "get", queryStringParameters: query }).then(
      (response) => {
        setDevices(response);
      }
    );
  }

  const refreshList = async (deviceData?: IDevices) => {
    if (deviceData) {
      const deviceUpdated = await requestDevice({
        method: "get",
        pathParameters: deviceData.id,
      });
      let targetIndex = devices?.findIndex(
        (device) => device.id === deviceUpdated.id
      );
      targetIndex = targetIndex ?? -1;
      if (targetIndex >= 0) {
        setDevices((oldState = []) => {
          oldState[targetIndex || 0] = deviceUpdated;
          return oldState;
        });
      } else {
        setDevices((oldState) => [...(oldState || []), deviceUpdated]);
      }
    } else {
      getDevices();
    }
  };

  useEffect(() => {
    if (!inspectionId) {
      navigate("/quality/inspections");
    } else {
      requestInspection({ method: "get", pathParameters: inspectionId }).then(
        (response: IInpectionDetails) => {
          setStage(response.currentStep);
          setInspectionDetails(response);
        }
      );
    }

    let query: { isApproved: boolean } | string = "";

    if (stage !== InspectionStepsEnum.INITIAL_VISUAL) {
      query = { isApproved: true };
    }

    getDevices({ query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage]);

  const nextBurninStage = () => {
    requestInspection({
      method: "patch",
      pathParameters: `${inspectionId}/step`,
    }).then((response: IInpectionDetails) => {
      setStage(response.currentStep);
      setInspectionDetails(response);
    });

    if (stage === InspectionStepsEnum.BURNIN_STATUS) {
      requestDevices({
        method: "put",
        pathParameters: "burnin-details",
      });
    }

    if (stage === InspectionStepsEnum.FINAL_VISUAL) {
      requestDevices({
        method: "put",
        pathParameters: "final-visual-inspection",
      });
    }

    if (stage === InspectionStepsEnum.PACKING) {
      requestDevices({
        method: "put",
        pathParameters: "packing-inspection",
      }).then(() => {
        navigate("/quality/inspections");
      });
    }
  };

  const filteredDevices = () => {
    if (!devices || !value) return devices;

    return devices?.filter((device) => {
      const uuid = String(device.activatorId).toLowerCase();
      const position = device.positionName?.toLowerCase();
      return (
        uuid.includes(value.toLowerCase()) ||
        position?.includes(value.toLowerCase())
      );
    });
  };

  const renderStages = () => {
    if (!inspectionId) return <></>;
    switch (stage) {
      case InspectionStepsEnum.INITIAL_VISUAL:
        return (
          <PreBurninInspection
            devices={filteredDevices() || []}
            inspectionId={inspectionId}
            refresh={refreshList}
          />
        );
      case InspectionStepsEnum.BURNIN_STATUS:
        return (
          <BurninInspection
            searchString={value}
            inspectionId={inspectionId}
            refresh={refreshList}
          />
        );
      case InspectionStepsEnum.FINAL_VISUAL:
        return (
          <PostBurninInspection
            devices={filteredDevices() || []}
            inspectionId={inspectionId}
            refresh={refreshList}
          />
        );
      case InspectionStepsEnum.PACKING:
        return (
          <ExpeditionInspection
            devices={filteredDevices() || []}
            inspectionId={inspectionId}
            refresh={refreshList}
          />
        );
    }
  };

  const renderTitle = (title: string) => {
    switch (title) {
      case "INITIAL_VISUAL":
        return "Inspeção Pré Burnin";
      case "BURNIN_STATUS":
        return "Inspeção Burnin";
      case "FINAL_VISUAL":
        return "Inspeção Pós Burnin";
      case "PACKING":
        return "Inspeção Expedição";
      default:
        return "Inspeção Finalizada";
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const devicesQuantity = useMemo(() => {
    return (
      devices?.filter(
        (device) => device.initialVisualInspectionStatus === "APPROVED"
      ).length ?? 0
    );
  }, [devices]);

  return (
    <PageTemplate>
      <Container>
        <Header>
          <Button onClick={() => navigate("/quality/inspections")}>
            Voltar
          </Button>
          <h2>{renderTitle(stage)}</h2>
          <span></span>
        </Header>
        <Lotdescription>
          {loadInspection ? (
            <>
              <InspectionPlaceholder />
              <InspectionPlaceholder />
              <InspectionPlaceholder />
            </>
          ) : (
            <>
              <div>
                <label>nome:</label>
                <span>{inspectionDetails?.name}</span>
              </div>
              <div>
                <div>
                  <label>Início: </label>
                  <span>
                    {inspectionDetails?.startDate &&
                      format(
                        new Date(inspectionDetails.startDate),
                        "dd/MM/yyyy HH:mm"
                      )}
                  </span>
                </div>
                <div>
                  <label>Lote: </label>
                  <span>{inspectionDetails?.deviceBatch}</span>
                </div>
              </div>
              <div>
                <div>
                  <label>Fim: </label>
                  <span>
                    {inspectionDetails?.endDate &&
                      format(
                        new Date(inspectionDetails.endDate),
                        "dd/MM/yyyy HH:mm"
                      )}
                  </span>
                </div>
                <div>
                  <label>Produto: </label>
                  <span>{inspectionDetails?.deviceName}</span>
                </div>
              </div>
            </>
          )}
        </Lotdescription>
        <AddSensorContainer>
          <div>
            <DebounceInput
              value={value}
              onChange={handleChange}
              debounceTimeout={300}
              placeholder="Pesquisar..."
            />
          </div>

          {stage === InspectionStepsEnum.INITIAL_VISUAL &&
            devicesQuantity < (inspectionDetails?.deviceQuantity || 0) && (
              <DeviceContainer
                inspectionId={inspectionId || ""}
                type={InspectionStepsEnum.INITIAL_VISUAL}
                handleNext={() => {}}
                currentPosition={devices?.length ?? 0}
                refresh={refreshList}
              >
                <AddDeviceButton>
                  <span>Adicionar</span>
                  <MdAdd />
                </AddDeviceButton>
              </DeviceContainer>
            )}
          <RefreshButton onClick={() => getDevices()}>
            Atualizar Lista
          </RefreshButton>
        </AddSensorContainer>
        <WrapperStages loading={loadDevice}>
          {loadDevices || loadInspection ? (
            <Spinner style={{ margin: "10% auto 0 auto" }} animation="border" />
          ) : (
            renderStages()
          )}
          {loadDevice && (
            <Spinner
              style={{ top: "50%", left: "50%", position: "absolute" }}
              animation="border"
            />
          )}
        </WrapperStages>

        <Footer>
          <StepProgress>
            <span>Progresso</span>
            <span>
              {devicesQuantity} / {inspectionDetails?.deviceQuantity || 0}
            </span>
          </StepProgress>
          <ModalConfirm
            message={`Tem certeza que deseja finalizar`}
            handleConfirm={() => {
              nextBurninStage();
              return true;
            }}
          >
            <button>Finalizar Etapa</button>
          </ModalConfirm>
        </Footer>
      </Container>
    </PageTemplate>
  );
}

function InspectionPlaceholder() {
  return (
    <>
      <Placeholder animation="glow">
        <Placeholder xs={8} />
        <Placeholder xs={8} />
      </Placeholder>
    </>
  );
}
